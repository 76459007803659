.coming-card {
    position: relative;
  }
  
  .coming-card a.add-to-calendar {
    background-image: linear-gradient(to right, #ff6c1c, #f0a401);
    color: #ffffff;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 10px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    gap: 15px;
    transition: 0.3s;
  }
  
  .coming-card a:hover {
    background: #191919;
  }
  @media (max-width: 1320px) {
    .coming-card a.add-to-calendar {
      text-transform: none;
    }
    
  }
  