.movieTrailer {
    position:absolute;
    content:'';
    top:125px;
    left:100px;
    right:100px;
    bottom:0;
    background: rgba(0,0,0,.5);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 40px rgba(255,255,255,.5);
    z-index:15;
    opacity:0;
    visibility:hidden;
    transition:1s;
}
.movieTrailer iframe {
    max-width:1280px;
    max-height:720px;
}
.movieTrailer .closeTrailer {
    position: absolute;
    top:30px;
    right:30px;
    cursor:pointer;
    color:#fff;
    font-size: 1.8rem;
}
.movieTrailer.active {
    opacity:1;
    visibility:visible;
}
