
@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

:root {
    --primary:#ff3700;
    --secondary: #ff6c1c;
    --tertiary:#f0a401;
    --banner-background: url('');
}
body {
    font-family: 'Roboto',sans-serif;
    background: #000000;
    color:#fff;
}
h1, h2, h3, h4, h5, h6 {
    font-family: sans-serif;
    margin: 0;
}
.container {
    max-width: 1140px;
    margin: 0 auto;
    width:100%;
}
.container-full {
    max-width: 1600px;
    padding: 0 15px;
    margin:0 auto;
}
html {
    font-size: 14px;
  }
.col-1 {
    flex-basis: 8.33%;
}
.col-2 {
    flex-basis: 16.66%;
    width:16.66%;
}
  
.col-3 {
    flex-basis: 25%;
}
  
.col-4 {
    flex-basis: 33.33%;
}
  
.col-5 {
    flex-basis: 41.66%;
}
.col-6{
    flex-basis: 50%;
    width:50%;
}
.row {
  display:flex;
  flex-wrap: wrap;
}
.d-flex {
    display:flex;
}
.flex-nowrap {
    flex-wrap: nowrap;
}
.relative {
    position:relative;
}
.flex-column {
    flex-direction: column;
}

.align-center {
    align-items: center;
}
.justify-center {
  justify-content: center;
}
.space-between {
    justify-content: space-between;
}
.vertical-padding {
    padding-top: 100px;
    padding-bottom: 100px;
}
.img-fluid {
    width: 100%;
    height:auto;
}
.mt-1 {
    margin-top:5px;
}
.mt-2 {
margin-top:10px;
}
.mt-3 {
margin-top:15px;
}
.mt-4 {
    margin-top:20px;
}
.mt-5 {
    margin-top:25px;
}
.mt-6 {
    margin-top:30px;
}
.mt-7 {
     margin-top:35px;
}
.mt-8 {
    margin-top:40px;
}
.mt-9 {
    margin-top:45px;
}
.mt-10{
    margin-top:50px;
}
.mt-16{
    margin-top:80px;
}
.mb-1 {
    margin-bottom:4px;
}
.mb-2 {
    margin-bottom:8px;
}
.mb-3 {
    margin-bottom:12px;
}
.mb-4 {
    margin-bottom:16px;
}
.mb-5 {
    margin-bottom:20px;
}
.mb-6 {
    margin-bottom:24px;
}
.mb-7 {
     margin-bottom:28px;
}
.mb-8 {
    margin-bottom:32px;
}
.mb-9 {
    margin-bottom:36px;
}
.mb-10 {
    margin-bottom:40px;
}
.mb-11 {
    margin-bottom:44px;
}
.mb-12 {
    margin-bottom:48px;
}
.mb-14 {
    margin-bottom:56px;
}
.mb-25 {
    margin-bottom: 100px;
}
.pt-1 {
    padding-top:4px;
}
.pt-2 {
    padding-top:8px;
}
.pt-3 {
    padding-top:12px;
}
.pt-4 {
    padding-top:16px;
}
.pt-5 {
     padding-top:20px;
}
.pt-6 {
    padding-top:24px;
}
.pt-7 {
    padding-top:28px;
}
.pt-8 {
    padding-top:32px;
}
.pt-9 {
    padding-top:36px;
}
.pt-10 {
    padding-top:40px;
}
.pt-13 {
    padding-top:52px;
  }
.pt-30 {
    padding-top:120px;
  }
.pb-1 {
    padding-bottom:5px;
  }
.pb-2 {
    padding-bottom:10px;
  }
.pb-3 {
    padding-bottom:15px;
  }
.pb-4 {
    padding-bottom:20px;
  }
.pb-5 {
    padding-bottom:25px;
  }
.pb-6 {
    padding-bottom:30px;
  }
  .pb-7 {
    padding-bottom:35px;
  }
  .pb-8 {
    padding-bottom:40px;
  }
  .pl-6 {
    padding-left:30px;
  }
[class^="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
 