
main {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 0 0 120px;
    background: #000000;
  }
  
  main::before {
    position: absolute;
    content: '';
    left: 0;
    top: -80px;
    width: 100%;
    height: 200px;
    background: #000000;
    filter: blur(50px);
  }
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  
  section {
    position: relative;
    overflow: hidden;
    padding: 120px 100px 20px;
    width: 100%;
    transition: ease-in-out 1s;
    transform-origin: bottom;
    z-index: 999;
  }
  
  section h1 {
    font-weight: 400;
    font-size: 4em;
    letter-spacing: 1px;
    margin-bottom: 150px;
    text-align: center;
    color: #ffffff;
  }
  
  section .section-title {
    position: relative;
    color: #ffffff;
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: 1px;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
  
  section .section-title::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -30px;
    height: 5px;
    width: 50%;
    background: linear-gradient(
      90deg,
      var(--primary),
      var(--primary),
      transparent
    );
    z-index: 1000;
  }
  
  section p {
    font-weight: 300;
    color: #111111;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
  
  @media (max-width: 768px) {
    section {
      padding: 120px 30px 20px;
    }
  }
  
  