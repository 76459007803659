
.filter .filter-wrapper {
    text-align: center;
}
.filter .filter-wrapper h1 {
    font-size: 3rem;
    }
.filter ul.buttons {
    padding: 0;
    gap: 10px;
}

.filter ul li .extra-large-button {
    background-color: transparent;
    border: 1px solid #EEB500;
    border-radius: 20px;
    color:#fff;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
   }
   .filter ul li .extra-large-button h3 {
    font-size:1.2rem;
   }
   .filter ul li .extra-large-button:hover {
    background-color:linear-gradient(to right, #ff6c1c, #f0a401);
    transform: scale(1.05);
}

.filter ul li .extra-large-button.active {
    color: white;
    background-color: #ff6c1c;
    background-image: linear-gradient(to right, #ff6c1c, #f0a401);
}

.filter .content .movie-list {
    align-items: stretch;
     padding: 50px 0 40px 0;
   }

.filter .content .movie-list .movie {
    height:100%;
    text-align: center;
    transition: transform 0.3s;
}

.filter .content .movie-list .movie:hover {
    transform: scale(1.05);
}

.filter .content .movie-list .movie .movie-poster {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter .content .movie-list .movie h3 {
    font-size: 1.2rem;
    margin:15px 0;
    color:#f1f1f1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.filter .pagination {
    margin: 30px 0;
    gap: 10px;
}

.filter .pagination button {
    background-color:#f1f1f1;
    color:#333;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    font-size: 1em;
}

.filter .pagination button:hover {
    background-color: #ff6c1c;
    background-image: linear-gradient(to right, #ff6c1c, #f0a401);
}

.filter .pagination button:disabled {
    background-color: #e0e0e0;
    transform: none;
}

@media (max-width: 1300px) {
    .filter .content .movie-list .movie {
      flex-basis: 18%;
      }
}
  
  @media (max-width: 1100px) {
      .filter .content .movie-list .movie {
      flex-basis: 33.33%;
    }
  }
  @media (max-width: 850px) {
    .filter .content .movie-list .movie {
    flex-basis:50%;
    }
  }
  @media (max-width: 600px) {
    .filter .content .movie-list .movie {
      flex-basis:100%;
    }
    .filter .content .movie-list .movie:hover {
        transform: scale(1);
    }
  }

