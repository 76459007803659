
.single-genre-header {
    background-image: url('/public/assets/movies/horror.webp');
    background-position: center center;
    background-size: cover;
    width: 100%;
    position: relative;
    z-index: 1;
    height: 60vh;
    min-height: 400px;
}
.single-genre-header::after{
    position: absolute;
    content: "";
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: linear-gradient(180deg,#000000 0%, #1414143D 100%);
    opacity: 1;
}
.single-genre-header .single-genre-title{
    z-index: 2;
    color: #ffffff;
    position: relative;
    text-align: center;
    padding-top: 220px;
    animation: fade-in 3s ease;
}
.single-genre-header .single-genre-title h1 {
    font-size: 70px;
    margin-bottom: 20px;
    text-transform: capitalize;
}
.single-genre-header .single-genre-title h3 {
    font-size: 1.1rem;
    
}

.single-genre .movie-list {
    /* overflow-x: auto; */
    padding: 50px 0 40px 0;
   }


.single-genre .movie-list .movie {
    text-align: center;
    transition: transform 0.3s;
    }


.single-genre .movie-list .movie:hover {
    transform: scale(1.05);
}


.single-genre .movie-list .movie .movie-poster {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.single-genre .movie-list .movie h3 {
    font-size: 1.2rem;
    margin: 15px 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@media (max-width: 1100px) {
    .single-genre .movie-list .movie  {
        flex-basis: 33.33%;
            
    }
    .single-genre .movie-list .movie:hover {
        transform: scale(1);
    }
}
@media (max-width: 750px) {
    .single-genre .movie-list .movie  {
        flex-basis: 50%;
      
    }
}
@media (max-width: 500px) {
    .single-genre .movie-list .movie  {
        flex-basis: 100%;
      
    }
}

