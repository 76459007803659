.search {
    position: relative;
    width: 300px;
    height: 40px;
}

.search input {
    width: 100%;
    height: 100%;
    padding: 10px 10px 10px 40px;
    border-radius: 4px;
    outline: none;
    background: transparent;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, .5);
}

.search ion-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #fff;
}

.search-box {
    position: absolute;
    top: 45px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background: rgba(184,184,184,.2);
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.search-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.search-box li {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.search-box li:last-child {
    border-bottom: none;
}

.search-box li img {
    width: 50px;
    height: 75px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 4px;
}

.search-box li .no-image {
    width: 50px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ccc;
    color: #fff;
    margin-right: 10px;
    border-radius: 4px;
}

.search-box li span {
    color: #fff;
}

.search-box li a {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: inherit;
    width: 100%;
}

.search-box li:hover {
    background: rgba(255, 255, 255, .1);
}

.error-message {
    padding: 10px;
    color: red;
}

