.hero{
    height: 100vh;
    min-height: 700px;
    background-image: url('/public/assets/movies/221513-P156QA-346.jpg');
    background-position: center center;
    background-size: cover;
    width: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.hero::after{
    position: absolute;
    content: "";
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: linear-gradient(180deg, #00000085 34%,#000 100%);
    opacity: 1;
}
.hero .main-button {
    width: 150px;
    height: 50px;
    background-color:var(--secondary);
    background-image: linear-gradient(to left,var(--secondary),var(--tertiary));
    border-radius: 30px;
    color: #fff;
    border: none;
    text-transform: capitalize;
    font-size: 15px;
}

.text{
    z-index: 2;
    color: #ffffff;
    align-items: center;
    position: relative;
    text-align: center;
    padding-top: 200px;
}
.text h1 {
    font-size: 10rem;
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: 0;
}
.text h3 {
    font-size: 2.4rem;
    margin: 0;
    padding: 0;
}
.text p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 25px;
    padding: 0 100px;
}
.text h1{
    transform: translateY(500%);
    Transition: all ease-in 1s;
    opacity: 0;
}
.text h3{
    transform: translateY(250%);
    Transition: all ease-in 1s;
    opacity: 0;
}
.text p{
    transform: translateY(250%);
    Transition: all ease-in 1s;
    opacity: 0;
}
.text button{
    transform: translateY(-500%);
    Transition: all ease-in 1s;
    opacity: 0;
}
.animation-both h1,
.animation-both h3,
.animation-both p,
.animation-both button,
.animation-both{
    transform: translateY(0) !important;
    opacity: 1 !important;
}
@media all and (max-width: 550px) {
    .text h1{
        font-size: 100px;
    }
}