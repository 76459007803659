
 header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 30px;
    transition: .5s;
    z-index: 1000;
  }
  
  header.scrolled {
    background: rgba(0, 0, 0, .6);
    border-bottom: 2px solid var(--primary);
    border-image: linear-gradient(90deg, transparent, var(--primary), transparent) 1;
    backdrop-filter: blur(20px);
    padding: 10px 30px;
  }
  .logo img {
    width:160px;
    height:auto;
  }
  .logo a {
    font-family: 'Racing sans one', cursive;
    font-size: 35px;
    color: #fff;
    letter-spacing: 2px;
    font-weight: 800;
    text-transform: uppercase;
  }
  
  .menu, .search, .main-btn, .hamburger-menu {
    display: flex;
  }
  
  .hamburger {
    display: none;
    cursor: pointer;
  }
  .hamburger ion-icon {
    font-size: 36px;
    color: #fff;
    
  }
  .hamburger-menu {
    display: none;
    position:relative;
    
  }
  .hamburger-menu ul {
    position: absolute;
    right: 20px;
    top: -15px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.5);
    gap: 10px;
    padding: 15px 25px;
    background: transparent;
  }
  header .header-wrapper .hamburger-menu ul li a {
    color:#fff;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor:pointer;
    transition:.3s;
}
header .header-wrapper .hamburger-menu ul li:hover a,.header .header-wrapper .hamburger-menu ul li.active a{
    color:var(--primary);
}
  
 
  
  @media (max-width: 1410px) {
    .container-full {
      width: 100%;
    }
    .header-wrapper {
      gap: 8px;
    }
  }
  @media (max-width: 1240px) {
    .menu, .search, .mainBtn {
      display: none;
    }
    .header-wrapper {
        justify-content:space-between;
    }
    .hamburger {
      display: block;
    }
    .hamburger-menu  {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 70px; 
      left: 0;
      width: 100%;
    }
    .hamburger-menu ul li.dropdown {
        position:relative;
    }
    .hamburger-menu ul li.dropdown ul{
        display:flex;
        position: absolute;

    }
  }
  @media (max-width: 750px) {
    header {
        padding:10px 15px;
    }
  }
  
  
