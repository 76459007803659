.banner .trailer {
    position:absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
}

.trailer p {
    color:var(--primary);
    font-size: 1.5rem;
    margin-left: 20px;
    margin-bottom: 0;
}
.banner .playBtn {
    position: relative;
    color:#fff;
    width:80px;
    height:80px;
    transition:1s;
    z-index:10;
    background: transparent;
    border-radius: 50%;
    backdrop-filter: blur(20px);
}
.banner .playBtn ion-icon {
    font-size: 4rem;
    color:var(--primary);
    cursor:pointer;
}
.banner .playBtn:before {
    position: absolute;
    top:-5px;
    left:-5px;
    right:-5px;
    bottom:-5px;
    content:'';
    background: transparent;
    border:10px solid #fff;
    border-radius: 50%;
    animation: button 1s linear infinite;
}
@keyframes button {
    0%,100% {
        opacity:.3;
        filter:blur(4px);
    }
    50% {
        opacity:1;
        filter:blur(10px)
    }
}