
.movie-detail {
     padding: 20px;
    background: #1e1e1e;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    overflow: hidden;
    color: #ffffff;
}

.movie-header {
    position: relative;
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-bottom: 1px solid #333;
    border-radius: 10px 10px 0 0;
}

.movie-header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px 10px 0 0;
}

.movie-content {
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 1;
}

.movie-poster img {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    width: 300px;
}

.movie-info {
    margin-left: 20px;
    color: #ffffff;
}

.movie-info h1 {
    margin: 0;
    font-size: 2.5em;
    color: #e50914;
}

.movie-info .director {
    margin: 10px 0;
    font-size: 1.2em;
    color: #bbb;
}

.movie-info .movie-meta {
    display: flex;
    gap: 10px;
    font-size: 1.1em;
}

.movie-info .watch-trailer {
    background-color: #e50914;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1em;
    margin: 20px 0;
    transition: background-color 0.3s ease;
}

.movie-info .watch-trailer:hover {
    background-color: #b20710;
}

.rating {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.rating-value {
    font-size: 2em;
    font-weight: bold;
    margin-right: 10px;
}

.rating-count {
    font-size: 1em;
    color: #bbb;
}

.movie-details, .cast-crew, .movie-photos {
    padding: 20px;
    background: #1e1e1e;
    margin-top: 20px;
    border-radius: 10px;
    color: #ffffff;
}

.movie-details h2, .cast-crew h2, .movie-photos h2 {
    margin-top: 0;
    color: #e50914;
}

.cast-crew ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.cast-crew li {
    text-align: center;
    color: #ffffff;
}

.cast-crew img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.photos-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.photos-grid img {
    width: calc(25% - 10px);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
