
.footer {
  background: transparent;
  padding: 0 0 30px 0;
  font-size:1.4rem;
  }
  
.footer .footer-top {
  background: url('../../../public/assets/movies/footer-bg.jpeg') no-repeat left top;
  background-size: contain;
  border-top: 1px solid var(--primary);
  border-bottom: 1px solid var(--primary);
  padding: 60px 0 30px 0;
}
  
.footer .footer-top .footer-info .logo {
  line-height: 0;
}
  
.footer .footer-top .footer-info .logo span {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1px;
  color: #ffffff;
  margin-top: 3px;
}
  
.footer .footer-top .footer-info p {
  color: #ffffff;
  font-size:1rem;
 }
  
.footer .footer-top .social-links a {
  font-size: 1.1rem;
  display: inline-block;
  color: #ffffff;
  line-height: 0;
  margin-right: 15px;
  transition: 0.3s;
  text-decoration: none;
}
  
.footer .footer-top .social-links a:hover {
  color: var(--primary);
}
  
.footer .footer-top h4 {
  font-size: .9rem;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}
  
.footer .footer-top .footer-contact p {
  line-height: 26px;
  color: #ffffff;
  font-size: 1rem;
}
  
.footer .copyright {
  text-align: center;
  padding-top: 30px;
  color: #ffffff;
}
  
.footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #ffffff;
}
  
.footer .credits a {
  text-decoration: none;
  color: var(--primary);
}
@media (max-width: 950px) {
    .footer .footer-top .footer-info {
    flex-basis:50%;
  }
  .footer .footer-top .footer-links{
      flex-basis:25%;
  }
  
}
@media (max-width: 750px) {
  .footer .footer-top .footer-info {
  flex-basis:50%;
}
.footer .footer-top .footer-links{
    flex-basis:50%;
}
.footer .footer-top .footer-info .logo span {
  font-size:1.4rem;
}
}
      

  