/* .banner-contactus {
    position:relative;
}
.banner-contactus:after {
    position:absolute;
    content:'';
    background: url(/public/assets/movies/banner.webp) 50% 0 repeat-y;
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: cover;
    width: 100%;
    height: 100%;
    float: left;
      padding-top: 75px;
    text-align: center;
}
.form-wrapper {
    padding:50px;
    background-color: #f8f8f8;
    align-items: flex-start;
}
 
.contact-form {
    flex: 3;
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
}
  
.contact-form h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}
  
.contact-form .divider {
    width: 50px;
    height: 2px;
    background-color: #ff8533;
    margin-bottom: 20px;
}
  
.contact-form .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
  
.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
  
.contact-form input[name="name"],
.contact-form input[name="email"] {
    width: 48%;
    margin-right: 2%;
}
  
.contact-form textarea {
    height: 100px;
    resize: none;
}
  
.submit-btn {
    padding: 15px 30px;
    background-color:  #ff4b4b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 2rem;
}
  
.form-wrapper .social-links {
    flex: 1;
    background-color: #ff8533;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   
    position:relative;
}
.social-links::before {
    background: #d27835;
    content: '';
    width: 80%;
    height: 20px;
    position: absolute;
    top: -20px;
    left: 10%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  
.social-links li {
    margin-bottom: 15px;
}
.social-links a {
    color: #fff;
    font-size: 16px;
}
  
.social-links a:hover {
    text-decoration: underline;
}
   */
   
  
  .banner-contactus {
    position: relative;
    height: 200px; 
    background: url('/public/assets/movies/banner.webp') center/cover no-repeat;
  }
  .banner-contactus h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    z-index: 1;
    text-transform: uppercase;
    font-weight: bold;
  }
  .banner-contactus:after {
    position:absolute;
    content:'';
    background:rgba(0,0,0,.4);
    background-size: cover;
    width: 100%;
    height: 100%;
    text-align: center;
}
  
.form-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px;
    background-color: #f6f6f6;
    width: 80%;
    margin: 0 auto;
}
  
  .contact-form {
    flex: 3;
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
  }
  
  .contact-form h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .contact-form .divider {
    width: 50px;
    height: 2px;
    background-color: #ff8533;
    margin-bottom: 20px;
  }
  
  .contact-form .form-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 48%;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .contact-form textarea {
    width: 100%;
    height: 100px;
    resize: none;
  }
  
  .submit-btn {
    padding: 15px 30px;
    background-color: #ff4b4b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .form-wrapper .social-links {
    flex: 1;
    background-color: #ff8533;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .form-wrapper .social-links::before {
    background: #d27835;
    content: '';
    width: 80%;
    height: 20px;
    position: absolute;
    top: -20px;
    left: 10%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .form-wrapper .social-links ul {
   text-align:center;
    padding: 0;
    margin: 0;
  }
  
  .form-wrapper .social-links li {
    margin-bottom: 15px;
  }
  
  .form-wrapper .social-links a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
  }
  
  .form-wrapper .social-links a:hover {
    text-decoration: underline;
  }
  